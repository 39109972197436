import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/SEO'

export default function LegalDetails({ data }) {
    const { html, frontmatter } = data.markdownRemark;
    const { title } = frontmatter;

    return (
        <Layout>
            <Seo title={title} />
            <div className="flex flex-col items-center max-w-7xl mx-auto min-h-60vh sm:min-h-40vh">
                <div className="text-ash-bright font-roboto font-bold text-3xl sm:text-4xl text-center filter drop-shadow-lg my-10">
                    {title}
                </div>
                <div className="text-ash-bright p-5" dangerouslySetInnerHTML={{ __html: html }}>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query LegalPageDetails($slug: String) {
        markdownRemark(frontmatter: {slug: {eq: $slug}}) {
            html
            frontmatter {
                title
            }
        }
    }
`;